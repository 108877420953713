import makeFAIcon from "utils/makeFAIcon";

import {
  faBars,
  faGlobe,
  faPlusCircle,
  faMinusCircle
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

export const BarsIcon = makeFAIcon(faBars);
export const LanguageIcon = makeFAIcon(faGlobe);
export const LinkedinIcon = makeFAIcon(faLinkedinIn);
export const PlusIcon = makeFAIcon(faPlusCircle);
export const MinusIcon = makeFAIcon(faMinusCircle);

export * from "config/CustomIcons";
