import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Linkedin = ({ className, userName, companyName }) => {
  const url = companyName
    ? `https://linkedin.com/company/${companyName}`
    : `https://linkedin.com/in/${userName}`;
  return (
    <CircleIcon className={className} href={url} iconName="LinkedinIcon"/>
  );
};

Linkedin.propTypes = {
  className: PropTypes.string,
  userName: PropTypes.string,
  companyName: PropTypes.string,
};

Linkedin.defaultProps = {
  className: null,
  userName: '',
  companyName: null,
}

export default Linkedin;
