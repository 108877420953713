import { curry, pathSatisfies, test } from "ramda";

const propFilter = curry(
  (pathList, regex) => pathSatisfies(test(regex), pathList));

/**
 * break down all data retrieved in index.js
 */
const breakDownAllNodes = (nodes) => {
  const filterByFileName = propFilter(["fields", "fileName"]);
  const filterByDirectoryName = propFilter(["fields", "directoryName"]);

  const pagesNodes = nodes.filter(filterByDirectoryName(/pages/i));
  const sectionsNodes = nodes.filter(filterByDirectoryName(/sections/i));
  const productNodes = nodes.filter(filterByDirectoryName(/products/i));

  const indexNode = nodes.find(filterByFileName(/index/i)) || {};
  const productsNode = nodes.find(filterByFileName(/products/i)) || {};
  const aboutNode = nodes.find(filterByFileName(/about/i)) || {};
  const contactNode = nodes.find(filterByFileName(/contact/i)) || {};
  const privacyNode = nodes.find(filterByFileName(/privacy/i)) || {};
  const imprintNode = nodes.find(filterByFileName(/imprint/i)) || {};
  const checkoutNode = nodes.find(filterByFileName(/checkout/i)) || {};

  const metadataNode = nodes.find(filterByFileName(/metadata/i)) || {};
  const navBarNode = nodes.find(filterByFileName(/navbar/i)) || {};
  const footerNode = nodes.find(filterByFileName(/footer/i)) || {};
  const formNode = nodes.find(filterByFileName(/form/i)) || {};
  const foundersNode = nodes.find(filterByFileName(/founders/i)) || {};

  return {
    pagesNodes,
    sectionsNodes,
    productNodes,

    indexNode,
    productsNode,
    aboutNode,
    contactNode,
    privacyNode,
    imprintNode,
    checkoutNode,

    metadataNode,
    navBarNode,
    footerNode,
    formNode,
    foundersNode,
  };
};

export default breakDownAllNodes;
