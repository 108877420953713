import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import getBaseUrl from "utils/getBaseUrl";
import * as SocialIcons from "components/SocialIcons";

const Footer = ({ frontmatter, pathContext, className }) => {
  const {
    copyright,
    privacyText,
    termsText,
    social: {
      linkedin,
    },
  } = frontmatter;
  const { langKey, defaultLang } = pathContext;

  const currentYear = new Date().getFullYear().toString();
  const yearText = currentYear === "2020" ? "2020" : `2020 - ${currentYear}`;

  return (
    <footer className={`footer bg-white mt-auto text-body ${className}`}
            style={{ zIndex: 2 }}>
      <Container fluid>
        <Row className="align-items-center text-center mx-md-0">
          <Col md={4}
               className="nav px-0 justify-content-center justify-content-md-start">
            <Link className="nav-link"
                  to={`${getBaseUrl(defaultLang, langKey)}imprint/`}>
              {privacyText}
            </Link>
            <Link className='nav-link'
                  to={`${getBaseUrl(defaultLang, langKey)}privacy/`}>
              {termsText}
            </Link>
          </Col>
          <Col md={4} className="mb-2 mt-md-2 my-lg-0">
            {linkedin ? <SocialIcons.Linkedin companyName={linkedin}/> : null}
          </Col>
          <Col md={4} className="mb-2 mb-md-0 text-md-right">
            {copyright.replace("yyyy", yearText)}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

Footer.defaultProps = {
  className: ''
};

export default Footer;
