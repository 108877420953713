import makeFAIcon from "utils/makeFAIcon";

import {
  faLaptop,
  faCoffee,
  faDraftingCompass,
  faHiking,
  faQrcode
} from "@fortawesome/free-solid-svg-icons";
import {
  faHubspot
} from "@fortawesome/free-brands-svg-icons";


export const LaptopIcon = makeFAIcon(faLaptop);
export const CoffeeIcon = makeFAIcon(faCoffee);
export const HikingIcon = makeFAIcon(faHiking);
export const QRIcon = makeFAIcon(faQrcode);
export const DraftingCompassIcon = makeFAIcon(faDraftingCompass);

export const HubspotIcon = makeFAIcon(faHubspot);
